.cover {
  > div {
    background-color: $purple;
    height: 600px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    .cover-inner {
      background-size: cover;
      width: 100%;
      height: calc(100% - 100px);
      box-shadow: $boxShadow;
    }
  }

  &.cover-home {
    > div {
      height: 100vh;
    }
  }
}