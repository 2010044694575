.nav {

  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin: 0 0 8px 0;
  }

  button {
    font-size: 1.4rem;
    background-color: $purple;
    border-color:  $purple;
    color: #fff;
    font-weight: bold;
    padding: 0rem 1rem 0rem 2rem;
    margin: 0 16px 16px 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;

    span {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    span:last-child {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      width: 2rem;
      height: 2rem;
      display: inline-block;
      margin-left: 2rem;
    }

    div {
      background-color: #f00;
      font-weight: bold;
      letter-spacing: 1px;
      padding: 1rem;
    }

    &:hover {
      background-color: $genericHover;
      border-color: $genericHover;
      color: #fff;
    }
  }

}


@media screen and (min-width: 768px) {
  .nav {
    button {
      font-size: 2rem;
    }
  }
}

@media screen and (min-width: 992px) {
  .nav {
    button {
      font-size: 1.8rem;
    }
  }
}

@media screen and (min-width: 1440px) {
  .nav {
    button {
      font-size: 2rem;
    }
  }
}

