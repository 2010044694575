.banner {
  padding-top: 1rem;

  h1 {
    font-size: 3.2rem;
    color: #fff;
    // text-transform: uppercase;
    margin: 0 0 16px;
    background-color: #330341;
    padding: 8px;
  }

  button {
    font-size: 1.4rem;
    background-color: $purple;
    color: #fff;
    border-color: #fff;
    font-weight: bold;
    padding: 1rem 2rem;
    margin: 0 16px 32px 0;
    display: block;
    border-radius: 0;


    &:hover {
      background-color: $genericHover;
      border-color: #fff;
      color: #fff;
    }
  }

  button:first-of-type {
    margin-bottom: 16px;
    background-color: $pink;
    &:hover {
      background-color: $genericHover;
      border-color: #fff;
    }
  }

}

@media screen and (min-width: 768px) {
  .banner {
    padding-top: 2rem;

    h1 {
      font-size: 3.9rem;
      margin: 0 0 32px;
      background-color: transparent;
      padding: 0;

      span {
        background-color: #330341;
        padding: 8px;
        display: inline-block;
        margin: 0 0 8px;
      }
    }

    button {
      font-size: 2rem;
    }
  }
}

@media screen and (min-width: 992px) {
  .banner {
    padding-top: 2rem;

    h1 {
      font-size: 4rem;
    }

    button {
      font-size: 1.8rem;
    }
  }
}

@media screen and (min-width: 1440px) {
  .banner {
    padding-top: 4rem;

    h1 {
      font-size: 5rem;
    }

    button {
      font-size: 2rem;
    }
  }
}

