$loadingColor: #d8d6d6;

.skeleton-wrapper {
  height: 369px;
  position: relative;
}

.skeleton {

    /* usefor for getting the styling right: */
    position: absolute;
    left: -18px;
    top: 81px;

    width: 100%;

    background-repeat: repeat-y;

    animation: pulse 1s infinite;

    height: 240px;

    background-image:
        linear-gradient( $loadingColor 50px, transparent 0 ),
        linear-gradient( $loadingColor 29px, transparent 0 );

    background-size:
        50px 92px,
        384px 94px;

    background-position:
        33px 0px,
        155px 14px;

}


@keyframes pulse {
    0% {
        opacity: .5
    }

    100% {
        opacity: 1
    }
}