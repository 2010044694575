table {
  width: 100%;
  margin: 0 0 2rem;

  table {
    margin: 0;
  }

  caption {
    display: none;
  }

  th, td {
    vertical-align: middle;
    padding: 16px 8px;
    border-color: $contentBackground;
    border-style: solid;
  }

  th {
    width: 40%;
    background-color: #5DB7DE;
    font-size: 1.3rem;
    border-width: 0 2px 2px 0;
  }

  td {
    background-color: #f2f2f2;
    font-size: 1.2rem;
    border-width: 0 2px 2px 0;
  }

  &.commitments {
    td {
      background-color: $assemblyDefault;
      color: #fff;
      font-weight: bold;

      &:last-child {
        text-align: center;
        background-color: $purple;
        a {
          color: #fff;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  td.td-small {
    width: 10%;
    border-width: 0 0 2px 0;
  }

  &.checklist-items {
    th, td {
      padding: 16px;
    }
    td {
      border-width: 0 0 2px 0;
    }
    .checklist-item-status {
      width: 10%;
    }
    .checklist-item-name {
      width: 90%;
    }
  }

  td.checklist-items {
    padding: 0;
  }
}