.accordion-item {
  border: none;
}

.accordion-button,
.accordion-button:not(.collapsed),
.accordion-button.collapsed {
  background-color: $assemblyDefault;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  padding: 0;
  align-items: center;
  border-radius: 0;
  border: solid 1px #fff;


  span {
    line-height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span:first-child {
    font-size: 2.6rem;
    background-color: #000000;
    flex-basis: 25%;
    max-width: 100px;
  }

  span:last-child {
    justify-content: flex-start;
    flex-basis: 100%;
    padding-left: 16px;
  }

  &::after {
    width: 3rem;
    height: 3rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-position: center center;
  }

}
