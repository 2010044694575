// wnsc-footer {
//   margin-top: auto;
//   z-index: 2;
// }

footer {
  color: #AFB2B3;
  padding-top: 32px;
  z-index: 2;
  margin-top: auto;
  background-color: #252325;

  h5 {
    font-size: 1.3rem;
  }

  p, a, address {
    font-size: 1.2rem;
  }

  .my-4 {
    margin-bottom: 0 !important;
  }

  a, a.nav-link {
    color: #fff !important;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  p.d-flex {
    align-items: center;
    span {
      padding-right: 8px;
    }
    img {
      width: 70px;
    }
  }
}