$purple: #9E0059;
$mayoralHover: #000000;
$mayoralActive: #EE266D;

$assemblyDefault: #2E86AB;
$assemblyHover: #000000;
$assemblyActive: #00AEEF;

$genericDefault: #353D42;
$genericHover: #000000;
$genericActive: #868B8E;

$menuTitleDefault: #353D42;
$menuTitleHover: #000000;
$menuTitleActive: #AFB2B3;

$menuArrowDefault: #D7D8D9;
$menuArrowHover: #000000;
$menuArrowActive: #AFB2B3;

$menuBackgroundDefault: #ffffff;
$menuBackgroundHover: #ffffff;
$menuBackgroundActive: #ffffff;

$bodyCopy: #353D42;
$keyLines: #E1E2E3;
$contentBackground: #ffffff;
$headerFooterBackground: #fff;
$status: #EE266D;
$warning: #BC9109;
$error: #E0001B;

$boxShadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

$headerHeight: 100;

$purple: #58247b;
$lightPurple: #842d88;
$pink: #b8358c;

