$checked-color: #34b93d;
$unchecked-color: #000;
$check-height: 25px;
$background-color:#667788;
$font-color:#efefef;
$duration: .4s;

@mixin styling($checkbox-height) {

  @-moz-keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: $checkbox-height/2; }
  }

  @-webkit-keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: $checkbox-height/2; }
  }

  @keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: $checkbox-height/2;  }
  }

  @keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: $checkbox-height * 1.2; }
  }
  @-webkit-keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: $checkbox-height * 1.2; }
  }
  @-moz-keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: $checkbox-height * 1.2; }
  }

  input[type=checkbox]{
    display:none;
  }

  .custom-checkbox {
    height: $checkbox-height;
    width: $checkbox-height;
    background-color: transparent;
    border: $checkbox-height * .1 solid $unchecked-color;
    border-radius: 5px;
    position: relative;
    display: inline-block !important;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-transition: border-color ease $duration/2;
    -o-transition: border-color ease $duration/2;
    -webkit-transition: border-color ease $duration/2;
    transition: border-color ease $duration/2;
    cursor:pointer;

    &::before, &::after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        height: 0;
        width: $checkbox-height * .2;
        background-color: $checked-color;
        display: inline-block;
        -moz-transform-origin: left top;
        -ms-transform-origin: left top;
        -o-transform-origin: left top;
        -webkit-transform-origin: left top;
        transform-origin: left top;
        border-radius: 5px;
        content: ' ';
        -webkit-transition: opacity ease .5;
        -moz-transition: opacity ease .5;
        transition: opacity ease .5;
    }

    &::before {
        top:$checkbox-height * .77;
        left: $checkbox-height * .49;
        // box-shadow: 0 0 0 $checkbox-height * .05 $background-color;
        -moz-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        transform: rotate(-141deg);
    }

    &::after {
        top: $checkbox-height * .51;
        left: $checkbox-height * .054;
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-47deg);
    }
  }

  input.custom-checkbox:checked,
  .custom-checkbox.checked{
    border-color:$checked-color;

    &::after{
        height: $checkbox-height * .5;
        -moz-animation: dothabottomcheck $duration/2 ease 0s forwards;
        -o-animation: dothabottomcheck $duration/2 ease 0s forwards;
        -webkit-animation: dothabottomcheck $duration/2 ease 0s forwards;
        animation: dothabottomcheck $duration/2 ease 0s forwards;
    }

    &::before{
        height: $checkbox-height * 1.2;
        -moz-animation: dothatopcheck $duration ease 0s forwards;
        -o-animation: dothatopcheck $duration ease 0s forwards;
        -webkit-animation: dothatopcheck $duration ease 0s forwards;
        animation: dothatopcheck $duration ease 0s forwards;
    }
  }

}


@media screen and (max-width: 800px) {
  @include styling($checkbox-height: 30px);
}
@media screen and (min-width: 801px) {
  @include styling($checkbox-height: 45px);
}