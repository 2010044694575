@import '~bootstrap/dist/css/bootstrap.css';
@import 'bootstrap-icons';
@import 'variables';
@import 'accordion';
@import 'banner';
@import 'carousel';
@import 'checkbox';
@import 'cover';
@import 'fab';
@import 'footer';
@import 'header';
@import 'home';
@import 'loader';
@import 'nav';
@import 'progress';
@import 'registration';
@import 'skeleton';
@import 'tables';
@import 'type';

html {
  height: 100%;
}

body {
  padding: 0;
  color: #111;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: $pink;
}

#root {
  height: 100%;
}

.app__wrapper {
  display: flex;
  min-height: 100vh;
}

.app__root {
  flex-direction: column;
  flex: 1 1;
  display: flex;
}

.app__content {
  flex-grow: 1;
}


.anchor-help {
  visibility: hidden;
}

.no-margin-bottom {
  margin-bottom: 0;
}

button {
  .spinner-border {
    border-width: 0.15em;
    margin: 0 0 0 1rem;
  }
}

.warning {
  a {
    color: red !important;
  }
  color: red !important;
}

.dropdown-menu {
  button {
    .spinner-border {
      border-width: 0.15em;
      width: 1.6rem;
      height: 1.6rem;
      margin: 0 0 0 0.5rem;
    }
  }
}

.nav-item {
  a {
    .spinner-border {
      border-width: 0.15em;
      width: 1.6rem;
      height: 1.6rem;
      margin: 0 0 0 0.5rem;
    }
  }
}

.checklist-item-spinner {
  background-color: #ccc;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner-border {
    border-color: #666;
  }
}

@media screen and (min-width: 801px) {
  .checklist-item-spinner {
    width: 45px;
    height: 45px;
  }
}

a,
a:link,
a:visited,
a:hover
a:active {
  text-decoration: underline;
  color: $purple;
}

a:hover {
  color: $mayoralHover;
}

a:active {
  color: $mayoralActive;
}

main {
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  margin-top: #{$headerHeight}px;

  section {
    background-color: #ffffff;
    box-shadow: $boxShadow;
    padding: 8px;
    position: relative;
    margin-top: 80px;

    ul {
      margin: 0 0 2rem;
    }

    li {
      margin: 0 0 1rem;
    }

    ul.no-list {
      li {
        list-style: none;
        margin-left: -2rem;
      }
    }

    .pull-out {
      background-color: #fff;
      padding: 16px;
      margin: 0 0 2rem 0;

      p:last-child,
      ul:last-child {
        margin-bottom: 0;
      }

      &.important {
        box-shadow: $boxShadow;
      }
    }

  }


  hr {
    margin: 2rem;
  }

}

form {

  label {
    font-size: 1.4rem;
    font-weight: bold;
  }

  .btn-primary {
    background-color: rgba(158, 0, 89, 1);
    border-color:  rgba(158, 0, 89, 1);

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(158, 0, 89, .8);
      border-color:  rgba(158, 0, 89, .8);
    }

    &:disabled {
      background-color: rgba(158, 0, 89, .5);
      border-color:  rgba(158, 0, 89, .5);
    }
  }


  .form-row {
    margin: 0 0 16px 0;

    &.form-check-row {
      padding-top: 16px;
    }

    input.form-check-input {
      display: block !important;
      margin-top: 8px;
    }

    label.form-check-label {
      font-size: 1.1rem;
      font-weight: normal;
    }

  }

  .form-control-lg {
    font-size: 1.4rem;
  }

  .form-error-input {
    border-color: red;
  }

  .form-error {
    color: red;
  }

}


@media screen and (min-width: 510px) {
  main {
    section {
      padding: 16px;
    }
  }
}

@media screen and (min-width: 768px) {
  main {
    section {
      padding: 32px;
      margin-top: 0;

      .with-quote {
        display: flex;
        flex-direction: row;

        blockquote {
          width: 35%;
          // margin: 0 0 1rem 1rem;
          margin: 0 1rem 2rem 0;
        }

        > p {
          width: 65%;
        }

        &.with-quote--right {
          flex-direction: row-reverse;
          blockquote {
            width: 35%;
            margin: 0 0 2rem 1rem;
          }
        }

      }
    }
  }
}