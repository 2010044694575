.btn-fab {
  border-radius: 50%;
  font-size: 2.2rem;
  height: 68px;
  margin: auto;
  min-width: 68px;
  width: 68px;
  padding: 0;
  overflow: hidden;
  background: rgba(158, 158, 158, 0.2);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  position: relative;
  line-height: normal;
  background-color: $purple;
  color: #fff;
  border: none;
  &.btn-dark {
    background-color: $genericDefault;
  }

  a {
    color: #fff !important;
  }
}

header {
  .btn-fab {
    box-shadow: none;
    // height: 60px;
    // min-width: 60px;
    // width: 60px;
    font-size: 2.6rem;
    background-color: #fff;
    color: #666;
    &:hover {
      color: #000;
    }
  }
}

.btn-fabs {
  position: absolute;
  right: 8px;
  top: -74px;

  &.btn-fabs-bottom {
    z-index: 3;
    position: fixed;
    bottom: 8px;
    right: 16px;
    width: 68px;
    left: auto;
    top: auto;

    .btn-fab:first-child {
      margin: 0 0 8px;
      opacity: 0;
      transition: opacity 0.5s;
    }
  }

  .btn-fab:first-child {
    margin: 0 4px 0;
  }
}

body.body--scrolled-active {
  .btn-fabs {
    &.btn-fabs-bottom {
      .btn-fab:first-child {
        opacity: 1;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .btn-fabs {
    top: -34px;
  }
}