.registration-modal {

  .modal-dialog {
    background-color: $contentBackground;

    .required {
      color: $error;
    }

    .modal-body {

      > div {
        padding-bottom: 32px;

        h4 {
          font-size: 1.8rem;
        }

        p,
        label {
          font-size: 1.2rem;
        }

        button {
          font-size: 2rem;
          color: #fff;
          background-color: $purple;
          border-color: $purple;

          &:hover {
            color: #fff;
            background-color: $mayoralHover;
            border-color: $mayoralHover;
          }
        }

        .row {

          > div:first-child {
            padding-bottom: 32px;
            border-bottom: solid 1px $keyLines;
          }

          > div:last-child {
            padding-top: 16px;

            button {
              background-color: $genericDefault;
              border-color: $genericDefault;
              &:hover {
                background-color: $genericHover;
                border-color: $genericHover;
              }
            }
          }

        }

      }
    }

  }

}

@media screen and (min-width: 768px) {
  .registration-modal {
    .modal-dialog {
      .modal-body {
        > div {
          .row {

            > div:first-child {
              padding-right: 16px;
              padding-bottom: 0;
              border-bottom: none;
            }

            > div:last-child {
              border-left: solid 1px $keyLines;
              padding-left: 16px;
              padding-top: 0;

              button {
                background-color: $genericDefault;
                border-color: $genericDefault;
                &:hover {
                  background-color: $genericHover;
                  border-color: $genericHover;
                }
              }
            }

          }
        }
      }
    }
  }
}