$fadeSpeed: 1;
$fadeOpacity: 0.7;

header {

  .header-background {
    background-color: $headerFooterBackground;
    height: #{$headerHeight}px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    // opacity: 0.9;
    box-shadow: none;
  }

  .header-top {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 4;

    > .container {
      padding: 0 16px;
      display: flex;

      div.navbar-partner {
        height: #{$headerHeight}px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        &:nth-child(1) {
          // justify-content: center;
          img {
            width: 180px;
          }
        }
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          // display: none;
          justify-content: flex-end;
          > div.d-flex > a,
          > div.d-flex > span {
            display: none
          }
        }
      }

      a {
        text-decoration: none;
        outline: none;
      }

    }
  }

  nav {
    display: none;
  }

  .dropdown-menu {
    max-width: 240px;
    button[disabled] {
      color: #666 !important;
    }
  }
}


@keyframes fadeIn {
  0% { opacity: $fadeOpacity; }
  100% {opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: $fadeOpacity; }
  100% {opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: $fadeOpacity; }
  100% {opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: $fadeOpacity; }
  100% {opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: $fadeOpacity; }
  100% {opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% {opacity: $fadeOpacity; }
}

@-moz-keyframes fadeOut {
  0% { opacity: 1; }
  100% {opacity: $fadeOpacity; }
}

@-webkit-keyframes fadeOut {
  0% { opacity: 1; }
  100% {opacity: $fadeOpacity; }
}

@-o-keyframes fadeOut {
  0% { opacity: 1; }
  100% {opacity: $fadeOpacity; }
}

@-ms-keyframes fadeOut {
  0% { opacity: 1; }
  100% {opacity: $fadeOpacity; }
}

@media screen and (min-width: 768px) {
  header {
    .header-background {
      will-change: opacity;
      // opacity: $fadeOpacity;
    }
    .header-top {
      > .container {
        div.navbar-partner {
          &:nth-child(1) {
            justify-content: flex-start;
            img {
              // width: 50px;
            }
            display: flex;
          }
          &:nth-child(3) {
            justify-content: flex-end;
            position: relative;
            display: flex;
            span {
              font-size: 2rem;
              color: #999;
              padding: 0 0 0 1rem;
            }
            a {
              img {
                width: 100px;
              }
              span {
                position: absolute;
                right: 190px;
                top: 40px;
                color: #333;
                font-size: 1rem;
                padding-right: 8px;
              }
            }

            // justify-content: flex-start;
            > div.d-flex > a,
            > div.d-flex > span {
              display: block
            }
          }
        }
      }
    }
  }
  body.body--scrolled-active {
    header {
      .header-background {
        animation: fadeIn #{$fadeSpeed}s;
        animation-fill-mode: forwards;
        box-shadow: $boxShadow;
      }
    }
  }

  body.body--scrolled-inactive {
    header {
      .header-background {
        animation: fadeOut #{$fadeSpeed}s;
        animation-fill-mode: forwards;
        box-shadow: none;
      }
    }
  }
}