#loading-bar {
  .bar {
    background-color: $purple;
  }
}
#loading-bar-spinner {
  .spinner-icon {
    border-top-color: $purple;
    border-left-color: $purple;
  }
}