main {
  > .container {
    > .home-container {
      > section {

        h2 {
          font-size: 2.6rem;
        }
        p {
          // font-size: 1.6rem;
          // line-height: 2.4rem;
          color: #000;

          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  main {
    > .container {
      > .home-container {
        p {
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  main {
    > .container {
      > .home-container {
        width: 80%;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  main {
    > .container {
      > .home-container {
        width: 80%;
      }
    }
  }
}

@media screen and (min-width: 1366px) {
  main {
    > .container {
      > .home-container {
        width: 70%;
      }
    }
  }
}