.carousel {
  margin: 16px 0 0 0;

  .carousel-caption {
    bottom: 3rem;
    z-index: 10;
  }

  .carousel-item {
    height: 16rem;
  }

  .carousel-caption {
    display: flex;
    top: 0;
    padding: 8px;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    border: solid 4px $purple;
    p {
      // color: $purple;
      color: #fff;
      font-weight: bold;
      font-size: 1.4rem;
      margin: 0;
      padding: 0;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
    width: 2rem;

    span:first-child {
      width: 2rem;
      height: 2rem;
      background-color: rgba(0, 0, 0, .9);
    }
  }

  .carousel-control-prev {
    justify-content: flex-start;
  }

  .carousel-control-next {
    justify-content: flex-end;
  }

  .carousel-indicators [data-bs-target] {
    display: none;
  }

}

@media screen and (min-width: 768px) {
  .carousel {

    .carousel-item {
      height: 18rem;
    }

    .carousel-caption {
      padding: 16px;
      p {
        font-size: 2rem;
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      width: 3rem;

      span:first-child {
        width: 3rem;
        height: 3rem;
      }
    }

    .carousel-indicators [data-bs-target] {
      display: block;
      opacity: 1;

      &.active {
        background-color: $purple;
      }
    }

  }
}

@media screen and (min-width: 992px) {
  .carousel {
    margin: 4rem 0 0 0;

    .carousel-control-prev,
    .carousel-control-next {
      width: 4rem;
    }
  }
}

@media screen and (min-width: 1440px) {
  .carousel {
    margin: 8.2rem 0 0 0;
  }
}