body {

  #tillr-loading {
      z-index: 2147483647;
      background-color: #dedede;
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      &.hidden {
          display: none;
      }

      .tillr-loading {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .tillr-loading div {
          position: absolute;
          top: 0;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background: #fd7300;
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .tillr-loading div:nth-child(1) {
          left: 8px;
          animation: tl-shape-1 0.6s infinite;
      }
      .tillr-loading div:nth-child(2) {
          left: 8px;
          animation: tl-shape-2 0.6s infinite;
      }
      .tillr-loading div:nth-child(3) {
          left: 32px;
          animation: tl-shape-2 0.6s infinite;
      }
      .tillr-loading div:nth-child(4) {
          left: 56px;
          animation: tl-shape-3 0.6s infinite;
      }

  }

}

@keyframes tl-shape-1 {
  0% {
      transform: scale(0);
  }
  100% {
      transform: scale(1);
  }
}
@keyframes tl-shape-3 {
  0% {
      transform: scale(1);
  }
  100% {
      transform: scale(0);
  }
}
@keyframes tl-shape-2 {
  0% {
      transform: translate(0, 0);
  }
  100% {
      transform: translate(24px, 0);
  }
}