html {
  font-size: 14px;
}

body {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

h1, h2, h3, h4 {
  font-weight: bold;
  margin: 0 0 20px;
  color: $purple;
}

h1.dark, h2.dark, h3.dark, h4.dark {
  color: $bodyCopy;
}

h1 {
  font-size: 2.286rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.286rem;
}

h4 {
  font-size: 1.2rem;
}

h5 {
  font-size: 1.2rem;
}

p.heading,
p.secondary-heading {
  font-weight: bold;
}

p.secondary,
p.secondary-heading {
  font-size: 1.3rem;
}

p {
  font-size: 1.3rem;
  line-height: 2rem;
  margin: 0 0 2rem;
}

li, th, td {
  font-size: 1.3rem;
  line-height: 2rem;
}

.dark {
  color: $bodyCopy !important;
}

blockquote {
  padding: 1rem;
  color: $purple;
  margin: 1rem 1rem 2rem;
  background-color: #dddde0;
}

blockquote > p {
  font-size: 1.6rem;
  margin: 0 0 2rem;

  &:last-child {
    margin: 0;
  }
}

.pull-out.important {
  h3 {
    font-size: 1.6rem;
  }
  h4 {
    font-size: 2rem;
  }
}

.with-quote {
  blockquote > p {
    font-size: 1.5rem;
  }
}

figure {
  margin: 1rem;
}

figure > blockquote > p:last-child {
  margin-bottom: 0;
}

figcaption {
  font-size: 1.6rem;
  margin-bottom: 2rem;
  color: $bodyCopy;
  text-align: right;
}

figcaption blockquote {
  margin-bottom: 0;
}
